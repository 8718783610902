import React, { FormEvent, HTMLProps, useState } from "react";

import "@fontsource/abel"; // Agency FB
import "@fontsource/poppins";

import { Navigation } from "./index";


const Input = ({ className, ...rest }: HTMLProps<HTMLInputElement>): JSX.Element => {
  const classList: string = className
    ? `bg-white p-2 text-gray-700 rounded-lg w-full outline-none focus:ring-4 focus:ring-gray-700 ${className}`
    : "bg-white p-2 text-gray-700 rounded-lg w-full outline-none focus:ring-4 focus:ring-gray-700";

  return (
    <input className={classList} {...rest} />
  );
};

const Form = (): JSX.Element => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setSubmitted(true);
  };

  return (
    <>
      {!submitted
        ?
          <form className="flex flex-col md:flex-row md:items-center space-y-5 md:space-x-5 w-full mt-5" onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-3 w-1/2" style={{ minWidth: "300px" }}>
              <Input type="text" placeholder="Name" />
              <Input type="email" placeholder="Email address" />
              <Input type="text" placeholder="Message" />
            </div>
            <button className="bg-black text-white px-12 py-2 rounded-lg outline-none focus:ring-4 focus:ring-gray-700" type="submit">SEND</button>
          </form>
        :
          <p className="mt-5">We are sorry, but there is currently a problem with this page. Please try again.</p>
      }
    </>
  );
};

const FormWrapper = (): JSX.Element => {
  return (
    <div className="bg-gray-200 p-8 max-w-2xl mt-5 rounded-lg">
      <div className="flex flex-wrap md:justify-between w-full space-x-3">
        <p>CONTACT US!</p>
        <p className="text-2xl" style={{ fontFamily: "Poppins" }}>www.cbdnow.com</p>
      </div>
      <Form />
    </div>
  );
};

const Body = (): JSX.Element => {
  return (
    <div className="bg-gray-100 px-10 py-10 flex-grow">
      <div className="flex flex-wrap justify-center">
        <p className="w-full text-center">If you have questions about CBD Now, please fill out the form below.</p>
        <FormWrapper />
        <p className="mt-5 w-full text-center">You may also call us at 800-555-1327 and leave a message.</p>
      </div>
    </div>
  );
};

const CBDNowPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Abel" }}>
      <Navigation />
      <Body />
      <Navigation />
    </div>
  );
};

export default CBDNowPage;
